<template>
    <div class="scroll-to-top" :class="{'phone': phoneOnly}">
        <v-slide-y-reverse-transition appear>
            <v-btn fab color="primary" :disabled="downDisabled" class="mr-2" depressed :large="pcOnly" @click.stop="scrollDown">
                <v-icon size="24">mdi-arrow-down</v-icon>
            </v-btn>
        </v-slide-y-reverse-transition>
        <v-slide-y-reverse-transition appear>
            <v-btn fab color="secondary" :disabled="upDisabled"  depressed :large="pcOnly" @click.stop="scrollUp">
                <v-icon size="24">mdi-arrow-up</v-icon>
            </v-btn>
        </v-slide-y-reverse-transition>
    </div>
</template>
<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import links from '@/helpers/links'

// import GlobalMethods from '@/helpers/global-methods'

export default {
    name: 'scroll-to-top',
    computed: {
        activeSection(){
            return this.$store.state.activeSection;
        },
        upDisabled(){
            return this.activeSection == links[0].name;
        },
        downDisabled(){
            return this.activeSection == links[links.length-1].name;
        },
        ...GlobalComputed
    },
    methods: {
        scrollDown(){
            let found = 0
            for(var i = 0; i < links.length - 1; i++){
                if(links[i].name==this.activeSection){
                    found = links[i+1]
                    break;
                }
            }
            if(found) {
                this.navigateToLink(found.ref);
            }
        },
        scrollUp(){
            let found = 0
            for(var i = 1; i < links.length; i++){
                if(links[i].name==this.activeSection){
                    found = links[i-1]
                    break;
                }
            }
            if(found) {
                this.navigateToLink(found.ref);
            }
            //this.$vuetify.goTo(0, {duration: 1000, container: document.documentElement, easing: 'easeOutQuint'});
        },
        ...GlobalMethods
    }
}
</script>
<style lang="scss" scoped>
.scroll-to-top{
    position: fixed;
    bottom: 4%;
    right: 60px;
    z-index: 4;

    &.phone{
        right: 30px;
    }
}
</style>