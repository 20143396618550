<i18n>
{
    "ru": {
        "contact": {
            "name": "Имя",
            "email": "Электронная почта",
            "title": "Тема сообщения",
            "content": "Cообщение",
            "send": "Отправить сообщение",
            "placeholders": {
                "name": "Заполните имя",
                "email": "Заполните электронную почту",
                "title": "Заполните тему сообщения",
                "content": "Заполните тело сообщения"
            }
        },
        "Thank you" : "Спасибо за сообщение. Мы обязательно свяжемся с вами.",
        "Required" : "Заполните поле",
        "Values should be mode then 4 chars" : "Неправильное значение поля",
        "Enter valid email" : "Неправильное значение почты"
    },
    "en": {
        "contact": {
            "email": "Email Address",
            "name": "Name",
            "title": "Message Subject",
            "content": "Message",
            "send": "Send Message",
            "placeholders": {
                "name": "Enter Your Name",
                "email": "Enter Your Email Address",
                "title": "Enter Message Subject",
                "content": "Enter Message Content"
            }
        },
        "Thank you" : "Thanks for the message. We will definitely contact you."
    }
}
</i18n>
<template>
<v-form v-model="isFormValid">
    <v-row>
        <v-col :cols="pcOnly ? 6 : 12">
            <v-text-field hide-details="auto" outlined v-model="from_name" :rules="rules"
                :label="$t('contact.name')" :placeholder="$t('contact.placeholders.name')"/>
        </v-col>
        <v-col :cols="pcOnly ? 6 : 12">
            <v-text-field hide-details="auto" outlined :rules="emailRules"
                v-model="from_email" :label="$t('contact.email')" :placeholder="$t('contact.placeholders.email')" />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-text-field hide-details="auto" outlined v-model="subject" :rules="rules"
                :label="$t('contact.title')" :placeholder="$t('contact.placeholders.title')" />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-textarea rows="4" hide-details="auto" v-model="message"
                :rules="rules"
                no-resize auto-grow outlined
                :label="$t('contact.content')" :placeholder="$t('contact.placeholders.content')" />
        </v-col>
    </v-row>
    <v-alert block dense dismissible prominent type="success" v-model="alert">{{$t('Thank you')}}</v-alert>
    <div class="contact-actions d-flex mt-4">
        <v-btn :disabled="!isFormValid" block x-large tile color="primary" @click.once="sendEmail">
            <span>{{$t('contact.send')}}</span>
            <v-icon class="ms-3">mdi-arrow-{{isRtl ? 'left' : 'right'}}</v-icon>
        </v-btn>
    </div>
</v-form>
</template>

<script>
import GlobalComputed from '@/helpers/global-computed'
import emailjs from 'emailjs-com'

export default {
    data() {
        return {
          from_name: '',
          from_email: '',
          subject: '',
          message: '',
          msg: {},
          isFormValid: false,
          alert : false
        }
    },
    computed: {
        rules() {
            return [
             v => !!v || this.$t('Required'),
             v => v.length >= 4 || this.$t('Values should be mode then 4 chars')
            ]
        },
        emailRules() {
            return [
             v => !!v || this.$t('Required'),
             v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(v) || this.$t('Enter valid email')
            ]
        },
        ...GlobalComputed,
    },
    methods: {
        sendEmail() {
          if(this.isFormValid){
            try {
              console.log('send contact form')
              emailjs.send('service_qvendru', 'template_qvendru', this.$data, 'nxCAlzDqkJF7iop4c')
              this.alert = true
            } catch(error) {
              console.log({error})
            }
            this.from_name = ''
            this.from_email = ''
            this.subject = ''
            this.message = ''
          }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>