<i18n>
{
    "ru": {

        "sections": {
            "1": {
                "title": "КюВенд - Компания по разработке программного обеспечения на основе искусственного интеллекта",
                "subtitle": "Мы предоставляем передовые программные решения для искусственного интеллекта, вендинговые платформы с ипользованием компьютерного зрения, которые расширяют возможности индустрии вендингового бизнеса."
            },
            "about": {
                "title": "О Нас",
                "subtitle": "Кто мы и каковы наши цели.",
                "row1": {
                    "title": "кто мы, что мы делаем и каковы наши цели?",
                    "content": "ООО \"КюВенд\" - компания по разработке программного обеспечения и технологий для вендингового оборудования , которая разрабатывает и владеет несколькими  системами и проектами с использованием современных технологий компьютерного зрения, направленными на поддержку и развитие вендингового бизнеса, развитие его алгоритмов интеллектуальной идентификации пользователей вендинговых аппаратов с использованием нейронных сетей. Компания также предоставляет программные платформы, продукты, услуги и аппаратные платформы, которые помогают и решают проблемы разработки программного обеспечения с использованием машинного обучения в любых отраслях электронного бизнеса в Российской Федерации."
                },
                "row2": {
                    "title": "Мы разрабатываем индивидуальное программное обеспечение и технологии!",
                    "content": "Команда ООО \"КюВенд\" использует новейшие методы программирования и проектирования для разработки программных сервисов, аппаратных систем, мобильных приложений и  компьютерных программ, что позволяет нам внедрять идеи и инновации в индивидуальном стиле и запускать современные, быстрые и мощные платформы с использованием искусственного интеллекта, которые включают в себя функции, беспрецедентные в области программного обеспечения в Российской Федерации!"
                }
            },
            "contact": {
                "subtitle": "Оставьте нам сообщение, и мы ответим как можно скорее"
            },
            "timeline": {
                "title": "История  нашей компании",
                "subtitle": "наиболее важные даты и события в истории ООО \"КюВенд\""
            },
            "products": {
                "title": "Наши продукты и проекты",
                "subtitle": "Платформы, услуги и решения, разработанные и управляемые ООО \"КюВенд\""
            },
            "industries": {
                "title": "Отрасли, которые мы развиваем",
                "subtitle": "Отрасли, над разработкой которых мы сейчас работаем и предоставляем программные решения для решения их проблем.",
                "cols": [
                    {
                        "headline": "Вендинговое оборудование",
                        "description": "Мы работаем над разработкой и эксплуатацией проектов и платформ, которые предоставляют решения для вендинговых терминалов с использованием уникальных платформ машинного обучения, в частности программные модули на основе компьютерного зрения для интеллектуальной дистанционной и бесконтактной идентификации пользователей вендингового оборудования по фотографии или видеопотоку, что является актуальным направлением развития современных вендинговых камер хранения и постаматов."
                    },
                    {
                        "headline": "Разработка ПО на заказ",
                        "description": "Мы работаем над разработкой замкнутых платформ и сервисов, программного обеспечения в различных сферах с применением компонентов машинного обучения - нейронные сети для идентификации, классификации, сегментации изображений, видео, текстов и других медиа данных."
                    },
                    {
                        "headline": "Разработка САПР",
                        "description": "Мы работаем над разработкой системы для автоматизированного проектирования (САПР) для проектирования нейронных сетей различных архитектур без использования языков программирования. Данная система объединяет в себе компоненты для сбора, обработки и анализа данных, модули разметки данных, модули моделирования и тренировки нейронных сетей, модули развертывания и представления в виде программных интерфейсов (API) результатов проектирования."
                    }
                ]
            },
            "actions": {
                "contact": "Обратная связь",
                "about": "Больше о нас",
                "products": "Наши продукты"
            }
        },
        "timeline": [
            {
                "title": "Создание ООО \"КюВенд\" для разработки программного обеспечения",
                "content": "Компания ООО \"КюВенд\" была основана для разработки и предоставления передовых программных решений в сфере машинного обучения, которые расширяют возможности потребителей и сотрудников компаний в различных сферах современного бизнеса."
            },
            {
                "date": "Скоро",
                "title": "Запуск первой системы/платформы",
                "content": "Запуск первой системы и платформы САПР для проектирования и внедрения нейронных сетей, разработанной ООО \"КюВенд\", для обеспечения ускоренного проектирования  систем интеллектуальной обработки данных с использованием машинного интеллекта и без необходимости использования языкорв программирования."
            }
        ],

        "contact": {
            "subheader": "или свяжитесь с нами через:",
            "email": "Элекстронная почта",
            "telegram": "Телеграм канал",
            "website": "Web сайт"
        },
        "dataItems" : {
            "projects": [
                {
                    "title": "Интеллектуальная камера хранения",
                    "subtitle": "Программно-аппаратный комплекс для интеллектуальной камеры хранения с дистанционной и бесконтактной идентификацией пользователя ячейки."
                },
                {
                    "title": "Моя фото коллекция",
                    "subtitle": "Программный комплекс для автоматического, интеллектуального сбора фотографий пользователя в мобильном приложении"
                },
                {
                    "title": "AIBB САПР",
                    "subtitle": "Система автоматизированного проектирования и внедрения продуктов в области машинного интеллекта"
                }
            ],
            "teamMembers": [
                {
                    "name": "Вадим Илен",
                    "job": "Основатель, Руководитель",
                    "avatar": "/img/team/veelen.jpg",
                    "description": "Основатель и руководитель ООО \"КюВенд\""
                },
                {
                    "name": "Павел Акимов",
                    "job": "Основатель, Архитектор",
                    "avatar": "/img/team/pakimov.jpg",
                    "description": "Основатель и архитектор ООО \"КюВенд\""
                }
            ],
            "section2Columns": [
                {
                    "headline": "headline1",
                    "description": "description1"
                },
                {
                    "headline": "headline2",
                    "description": "description2"
                },
                {
                    "headline": "headline3",
                    "description": "description3"
                }
            ]
        }
    },
    "en": {
        "sections": {
            "1": {
                "title": "QVend LLC - Artificial Intelligence Software Development Company",
                "subtitle": "We provide advanced software solutions for artificial intelligence, vending platforms with the use of computer vision, which expand the capabilities of the vending business industry."
            },
            "about": {
                "title": "About Us",
                "subtitle": "Who we are and What are our goals.",
                "row1": {
                    "title": "who we are, What we do, and What are our goals?",
                    "content": "QVend LLC is a company for the development of software and technologies for vending equipment, which develops and owns several systems and projects using modern computer vision technologies aimed at supporting and developing the vending business, developing its algorithms for intelligent identification of users of vending devices using neural networks. The Company also provides software platforms, products, services and hardware platforms that help and solve the problems of software development using machine learning in any e-business industries in the Russian Federation."
                },
                "row2": {
                    "title": "We develop custom softwares and technology!",
                    "content": "QVend's team uses the latest programming and design techniques to develop websites, systems, Android and IOS applications, as well as computer programs, which enables us to implement ideas and innovations in a custom fashion and launch modern, fast, and powerful platforms that include features unprecedented in other systems in the world!"
                }
            },
            "contact": {
                "subtitle": "Leave us a message and we'll reply as soon as possible"
            },
            "timeline": {
                "title": "Timeline of our history",
                "subtitle": "the most important dates and events in QVend's history"
            },
            "products": {
                "title": "Our Products & Projects",
                "subtitle": "Platforms, Services and Solutions that are developed and managed by QVend LLC"
            },
            "industries": {
                "title": "Industries We Develop",
                "subtitle": "Industries that we are currently working on developing it and providing software solutions to solve their problems.",
                "cols": [
                    {
                        "headline": "Vending equipment",
                        "description": "We are working on the development and operation of projects and platforms that provide solutions for vending terminals using unique machine learning platforms, in particular computer vision-based software modules for intelligent remote and contactless identification of users of vending equipment by photo or video flow, which is the current direction of development of modern vending storage cameras and terminals."
                    },
                    {
                        "headline": "Custom Software Development",
                        "description": "We are working on the development of closed-loop platforms and services, software in various fields using machine Learning components - neural networks for identification, classification, segmentation of images, video, texts and other media data."
                    },
                    {
                        "headline": "CAD ML products",
                        "description": "We are working to develop a computer-aided design (CAD) system for designing neural networks of various architectures without the use of programming languages. This system combines components for data collection, processing and analysis, data markup modules, modules for modeling and training neural networks, modules for deployment and presentation in the form of software interfaces (APIs) of design results."
                    }
                ]
            },
            "actions": {
                "contact": "Contact Us",
                "about": "More About Us",
                "products": "Our Products"
            }
        },
        "timeline": [
            {
                "title": "Founding QVend for Software Development",
                "content": "QVend LLC was founded to develop and provide advanced software solutions in the field of machine learning that expand the capabilities of consumers and employees of companies in various areas of modern business."
            },
            {
                "date": "Soon",
                "title": "Launching the first system / platform",
                "content": "Launching of the first CAD cloud platform for the design and implementation of neural networks developed by QVend LLC, to enable faster design of intelligent data processing systems using machine intelligence and without the need for programming languages."
            }
        ],
        "contact": {
            "subheader": "or contact us via:",
            "email": "Email Address",
            "telegram": "Telegram channel",
            "website": "Website"
        },
        "dataItems" : {
            "projects": [
                {
                    "title": "QVend terminal",
                    "subtitle": "Software and hardware vending system for intelligent storage with contactless cell user identification"
                },
                {
                    "title": "Exiting photo collection",
                    "subtitle": "Software service for automatic collection of user's photos in a mobile application"
                },
                {
                    "title": "AIBB CAD",
                    "subtitle": "Computer Aided Design and Implementation System for Machine Intelligence Products"
                }
            ],
            "section2Columns": [
                {
                    "headline": "headline1",
                    "description": "description1"
                },
                {
                    "headline": "headline2",
                    "description": "description2"
                },
                {
                    "headline": "headline3",
                    "description": "description3"
                }
            ]
        }
    }
}
</i18n>
<template>
    <div class="page page-sections">
        <section id="headerSection" data-section="home" class="header-section dark-gradient fullpage-section">
            <animated-background />
            <v-container v-if="!loading" class="fill-height pt-12" :class="{'align-center': phoneOnly}">
                <v-slide-y-transition appear >
                <h1 class="strong-text--text">
                    <animate-text :duration="1500" :stop-after="0" :delay="500" :text="$t('sections.1.title')" />
                </h1>
                </v-slide-y-transition>
                <v-slide-x-transition appear>
                <h2 class="text--text mt-5">
                    <animate-text @done="showSection1Actions = true" :duration="2500" :delay="2000" :text="$t('sections.1.subtitle')"></animate-text>
                </h2>
                </v-slide-x-transition>
                <div class="actions" :class="{'fade-up-off': !showSection1Actions, 'fade-up-on': showSection1Actions}">
                    <v-btn @click="navigateToLink('#productsSection')" color="strong-text" tile :large="pcOnly" depressed outlined class="me-6 mb-1">{{$t('sections.actions.products')}}</v-btn>
                    <v-btn @click="navigateToLink('#aboutSection')" color="strong-text" tile :large="pcOnly" depressed outlined class="me-6 mb-1">{{$t('sections.actions.about')}}</v-btn>
                    <v-btn @click="navigateToLink('#contactSection')" color="secondary" tile :large="pcOnly" outlined>{{$t('sections.actions.contact')}}</v-btn>
                </div>
                <scroll-to-top />
            </v-container>
        </section>
        <e-section id="aboutSection" data-section="about" :headline="$t('sections.about.title')" :subtitle="$t('sections.about.subtitle')" :cols="section2Columns" light divider>
            <v-row>
                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-left' : 'fade-right') : 'fade-up'" data-aos-duration="800" :cols="pcOnly ? 5 : 12" class="">
                    <v-img src="/img/vector1.png" contain width="100%" max-height="320"></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-right' : 'fade-left') : 'fade-up'" data-aos-duration="800" class="mt-6 mt-lg-0" :cols="pcOnly ? 6 : 12">
                    <h1 class="headline">{{$t('sections.about.row1.title')}}</h1>
                    <p class="medium-text text-light--text mt-6 mt-lg-8">
                        {{$t('sections.about.row1.content')}}
                    </p>
                    <div class="actions">
                        <v-btn @click="navigateToLink('#industriesSection')" tile large depressed color="primary" class="mb-1">{{$t('sections.industries.title')}}</v-btn>
                        <v-btn @click="navigateToLink('#timelineSection')" tile large depressed color="primary" outlined class="ms-lg-4">{{$t('sections.timeline.title')}}</v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row style="margin-top: 3.5rem" class=" flex-row-reverse">

                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-right' : 'fade-left') : 'fade-up'" data-aos-duration="800" :cols="pcOnly ? 5 : 12" class="">
                    <v-img src="/img/vector2.png" contain width="100%" max-height="320"></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col :data-aos="pcOnly ? (isRtl ? 'fade-left' : 'fade-right') : 'fade-up'" data-aos-duration="800" class="mt-6 mt-lg-0" :cols="pcOnly ? 6 : 12">
                    <h1 class="headline">{{$t('sections.about.row2.title')}}</h1>
                    <p class="medium-text text-light--text mt-6 mt-lg-8">
                        {{$t('sections.about.row2.content')}}
                    </p>

                    <div class="actions">
                        <v-btn @click="navigateToLink('#productsSection')" tile large depressed color="primary">{{$t('sections.products.title')}}</v-btn>
                        <!-- <v-btn @click="navigateToLink('#timelineSection')" tile large depressed color="primary" outlined class="ms-4">{{$t('sections.timeline.title')}}</v-btn> -->
                    </div>
                </v-col>
            </v-row>
        </e-section>

        <cols-section id="industriesSection" :headline="$t('sections.industries.title')" :subtitle="$t('sections.industries.subtitle')" :cols="industriesCols">

        </cols-section>


        <e-section id="productsSection" data-section="products" background="light" :headline="$t('sections.products.title')" :subtitle="$t('sections.products.subtitle')" light>
            <v-row class="flex-wrap justify-center">
                <v-col v-for="(project, i) in projects" :key="i" :cols="pcOnly ? 4 : 12">
                    <v-card v-ripple="project.href ? {class: 'soft-ripple'} : false" data-aos="fade-up" :data-aos-duration="800" :data-aos-delay="400*i" class="card-shadow fill-height" :href="project.href ? project.href : ''" :target="project.href ? '_blank' : ''">
                        <v-img :src="project.image" cover height="250" />
                        <div class="py-1 px-3">
                            <v-card-title class="primary--text mb-1">{{project.title}}</v-card-title>
                            <v-card-subtitle>{{project.subtitle}}</v-card-subtitle>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </e-section>

        <e-section id="timelineSection" data-section="timeline" divider :headline="$t('sections.timeline.title')" :subtitle="$t('sections.timeline.subtitle')" light>
            <timeline data-aos="fade-up" :data-aos-duration="800" :data="timeline" />
        </e-section>

        <e-section id="contactSection" data-section="contact" :headline="$t('links.contact')" :subtitle="$t('sections.contact.subtitle')" light>
            <v-row class="justify-center px-lg-12">
                <v-col :cols="pcOnly ? 6 : 12" class="ps-lg-12">
                    <contact-form data-once :data-aos="'fade'" :data-aos-duration="800" />
                </v-col>
                <v-col v-if="pcOnly" cols="1" class="text-center">
                    <v-divider vertical></v-divider>
                </v-col>
                <v-col :cols="pcOnly ? 3 : 12">
                    <div :data-aos="'fade'" :data-aos-duration="800">
                        <v-subheader>
                            {{$t('contact.subheader')}}
                        </v-subheader>
                        <v-list two-line>
                            <v-list-item v-for="item in contactItems" :key="item.name" :target="item.href ? '_blank' : ''" :href="item.href ? item.href : (item.name === 'email' ? `mailto:${item.value}` : '')">
                                <v-list-item-icon>
                                    <v-icon :color="item.color">{{item.icon}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="mb-2">{{$t('contact.' + item.name)}}</v-list-item-subtitle>
                                    <v-list-item-title>{{item.value}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                    </div>
                </v-col>
            </v-row>
        </e-section>


        <!-- <e-section headline="Team" subtitle="Our team" light>
            <v-row>
                <v-col v-for="member in teamMembers" cols="4" :key="member.name">
                </v-col>
            </v-row>
        </e-section> -->

        <e-footer />

        <scroll-to-top />
    </div>
</template>

<script>
// @ is an alias to /src
import 'particles.js';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
const merge = require('lodash').merge


import ScrollToTop from '@/components/custom/scroll-to-top'
import AnimateText from '@/components/custom/animate-text'
//import ScrollIcon from '@/components/custom/scroll-icon'
import ContactForm from '@/components/custom/contact-form'
import AnimatedBackground from '@/components/custom/animated-background'
import ColsSection from '@/components/default/cols-section'
import ESection from '@/components/default/e-section'
import Timeline from '@/components/default/timeline'
import EFooter from '@/components/main/e-footer'


import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    name: "Home",
    components: {
        ScrollToTop,
        ContactForm,
        AnimateText,
        Timeline,
        ColsSection,
        ESection,
        //ScrollIcon,
        EFooter,
        AnimatedBackground
    },
    data(){
        return merge({
            showSection1Actions: false,
            currentScrollTop: 0,
            
            contactItems: [
                {name: 'email', icon: 'mdi-email', value: 'info@qvend.ru', color: 'grey'},
                {name: 'telegram', icon: 'mdi-send-circle', value: 'qvendru', color: 'grey', href: 'https://t.me/qvendru'},
                {name: 'website', icon: 'mdi-web', value: 'www.qvend.ru', color: 'grey', href: 'https://qvend.ru'},
            ],
            projects: [
                {
                    image: '/img/qvend.png',
                },
                {
                    image: '/img/mria.jpg',
                },
                {
                    image: '/img/aibb.png',
                },
            ],
            section2Columns: [
                {
                    color: 'success',
                    icon: 'mdi-cart'
                },
                {
                    color: 'blue',
                    icon: 'mdi-cube-send'
                },
                {
                    color: 'deep-orange',
                    icon: 'mdi-cash-multiple'
                }
            ],
        }, this.$t('dataItems'))
    },
    computed: {
        industriesCols(){
            return [
               {color: 'blue', icon: 'mdi-human-male-board-poll', headline: this.$t('sections.industries.cols.0.headline'), description: this.$t('sections.industries.cols.0.description')},
               {color: 'deep-orange', icon: 'mdi-desktop-classic', headline: this.$t('sections.industries.cols.1.headline'), description: this.$t('sections.industries.cols.1.description')},
               {color: 'teal', icon: 'mdi-file-cad', headline: this.$t('sections.industries.cols.2.headline'), description: this.$t('sections.industries.cols.2.description')},
            ];
        },
        timeline(){
            return [
                {date: '2022-06-28', title: this.$t('timeline.0.title'), content: this.$t('timeline.0.content')},
                {date: this.$t('timeline.1.date'), title: this.$t('timeline.1.title'), content: this.$t('timeline.1.content')}
            ];
        },
        ...GlobalComputed
    },
    methods: {
        scrollToSection(n){
            let i = n-1,
                element = document.querySelectorAll('.page-sections section')[i];
            if (element){
                this.scrollToElement(element);
            }
        },
        scrollObserver(){
            window.addEventListener('scroll', ()=>{
                this.currentScrollTop = document.documentElement.scrollTop;
            })
        },
        handleScrollChange(scrollTop){
            const sections = document.querySelectorAll('.page-sections section');
            sections.forEach(section => {
                let offsetTop = section.offsetTop - this.navbarHeight, // decrease navbarHeight
                    offsetBottom = offsetTop + section.offsetHeight;

                if (scrollTop >= offsetTop && scrollTop < offsetBottom){
                    let sectionName = section.getAttribute('data-section');
                    if(sectionName){
                        this.$store.commit('SET_ACTIVE_SECTION', sectionName)
                    }
                    // section is visible...
                    if (section.getAttribute('data-theme') === 'light'){
                        
                        if (!this.lightNavbar){
                            this.$store.commit('SET_LIGHT_NAVBAR', true);
                        }
                    }else{
                        if (this.lightNavbar){
                            this.$store.commit('SET_LIGHT_NAVBAR', false);
                        }
                    }
                }
            })

        },

        ...GlobalMethods
    },
    watch: {
        currentScrollTop(top){
            this.handleScrollChange(top);
        }
    },

    mounted(){
        this.scrollObserver();
        AOS.init({container: document.documentElement, once: true, easing: 'ease'});
    }
};
</script>
<style lang="scss" scoped>
    section{

        .container{
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            &, *{
                z-index: 1;
            }
        }
        &.header-section{
            color: white;
        }
        h1{
            font-size: 3.2rem;
            font-weight: 380;

            @media(max-width: 960px){
                text-align: center;
                font-size: 1.7rem;
                line-height: 2.4rem;
                font-weight: 400;
            }
        }
        h2{
            font-size: 2.4rem;
            word-break: break-word!important;
            font-weight: 200;

            @media(max-width: 960px){
                text-align: center;
                font-size: 1.3rem;
                font-weight: 300;
            }
        }
        .actions{
            margin-top: 3rem;
            @media(max-width: 960px){
                margin: 2rem 0;
                width: 100%;
                text-align: left;
                justify-content: center;
            }
            .v-btn{
                @media(min-width: 960px){
                    min-width: 130px;
                    min-height: 45px;
                }
            }
        }
        .mdi-send-circle {
            transform: rotate(-30deg);
        }
        .headline {
            text-align: start;
        }
    }
</style>